//textDecoration
.grey_14 {
  font-size: 14px;
  color: $secondary;
}

.label_nav {
  color: $primary;
  cursor: pointer;
}

.click-text {
  font-size: 14px;
  cursor: pointer;
  color: $secondary;
  user-select: none;
  text-decoration: underline;
}

.grey_14_table {
  font-size: 14px;
  color: $secondary;
  text-transform: uppercase;
  background-color: $bg-color;
}
//link
.link-info-component {
  cursor: pointer;
  &:hover {
    color: $primary;
  }
}

.text-hover-underline {
  &:hover {
    cursor: pointer;
    text-decoration: underline;
    background: $primary-light;
  }
}

.text-hover {
  &:hover {
    cursor: pointer;
    user-select: none;
    background: $primary-light;
  }
}

.onClick-content {
  cursor: pointer;
  overflow-x: auto;
  &:hover {
    color: $primary;
    transform: scale(1.01);
    transition: transform 0.2s ease-in-out;
  }
}
//video-container
.video-container {
  position: relative;
  padding: 56.25% 0 0 0;
  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.option-for-question-AI {
  gap: 8px;
  margin: 8px;
  display: flex;
  padding: 0px 4px;
  align-items: center;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
  .react-player {
    top: 0;
    left: 0;
    position: absolute;
  }
}
//footer
.footer {
  display: flex;
  padding: 20px 0;
  text-align: center;
  align-items: center;
  justify-content: center;
}
//status style
@mixin forms($color) {
  margin: 0;
  color: #fff;
  padding: 3px 6px;
  text-align: center;
  border-radius: 3px;
  background-color: $color;
}

.passed {
  @include forms($color: $success);
}
.failed {
  @include forms($color: $danger);
}
.open {
  @include forms($color: $primary);
}
.completed {
  @include forms($color: $info);
}
.warning {
  @include forms($color: $warning);
}

// .circle {
//   width: 40px;
//   height: 40px;
//   display: flex;
//   border-radius: 50%;
//   align-items: center;
//   justify-content: center;
//   background-color: $bg-color;
//   .call-notification {
//     top: 0;
//     left: 100%;
//     position: absolute;
//     background-color: $primary;
//     transform: translateX(-60%) translateY(-40%);
//   }
//   .circle.text-muted:hover svg {
//     color: $primary;
//   }
// }

.settings-menu {
  padding: 3px 5px;
  &:hover {
    color: $primary;
    cursor: pointer;
    border-radius: 5px;
    background-color: $primary-light;
  }
  svg {
    margin-bottom: 5px;
  }
}

.avatar {
  width: 5rem;
  height: 5rem;
  position: relative;
  display: inline-block;
}

.avatar-md {
  max-height: 2.5rem;
  max-width: 2.5rem;
  min-width: 2.5rem;
  min-height: 2.5rem;
}

.avatar-xl {
  max-width: 5rem;
  min-width: 5rem;
  max-height: 5rem;
  min-height: 5rem;
}

.avatar-indicators::before {
  right: 5%;
  bottom: 0;
  width: 30%;
  height: 30%;
  content: "";
  border-radius: 50%;
  position: absolute;
  border: 1px solid #fff;
}

.avatar-online::before {
  background-color: $success;
}

.settings-title {
  width: 200px;
  max-width: 100%;
  text-transform: uppercase;
}

.icon-style {
  color: $primary;
  cursor: pointer;
  margin-left: 5px;
}

.icon-to-chapter {
  color: black;
  cursor: pointer;
  margin-left: 5px;
}

.avatar-employee-info-component {
  width: 80px;
  height: 80px;
  bottom: 25px;
  margin-left: 10px;
  border-radius: 50%;
  position: relative;
}

.svg-info-component {
  width: 16px;
  height: 16px;
}

.show-user-notify {
  display: none;
}

.nav-bar-logo {
  img {
    height: 60px;
    max-width: 100%;
  }
}

.square {
  margin: 5px;
  width: 12px;
  height: 12px;
}

td.folder-style {
  cursor: pointer;
  font-size: 14px;
  user-select: none;
  background-color: #f5f4f8;
}

.report-page {
  padding: 10px 5px;
  background: #fff;
  text-align: center;
  .report-header {
    font-size: 28px;
  }
  .report-settings {
    font-size: 20px;
    padding-bottom: 6px;
  }
}

.report-criteria {
  font-size: 20px;
  text-align: center;
  padding-bottom: 6px;
}

.customize-label {
  user-select: none;
  white-space: nowrap;
}

.cut-cur-year .flatpickr-current-month .numInputWrapper {
  all: unset;
  display: none !important;
}

.report-checkboxes {
  display: flex;
  margin-top: 8px;
  justify-content: space-between;
}

.leader-board-page {
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  &.background {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../image/background/leaderboard/gaming.jpg);
  }
  .leader-board-container {
    @extend %transparent;
    width: 100%;
    color: white;
    margin: 0 auto;
    max-width: 700px;
    .leader-board-title {
      @extend %contrast-text;
      font-size: 45px;
      user-select: none;
      text-align: center;
      margin: 10px 0 0 0;
      text-transform: uppercase;
    }
    .btn-group {
      width: 100%;
      .btn {
        font-size: 18px;
        text-wrap: nowrap;
      }
    }
    .leader-board-scroll {
      margin: 10px 0;
      overflow-y: auto;
      height: calc(100vh - 152px);
      &:hover::-webkit-scrollbar-thumb {
        background: $primary;
      }
    }
  }
  .leaderboard-theme {
    right: 20px;
    bottom: 20px;
    position: fixed;
  }
}

.leader-board-card {
  margin: 4px;
  padding: 4px;
  opacity: 0.75;
  display: flex;
  color: #000;
  font-size: 20px;
  align-items: center;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 16px !important;
  &.user {
    background-color: $primary-light !important;
  }
  .name {
    flex: 1 1 auto;
    img {
      margin: 0 4px;
      min-width: 30px;
      max-width: 30px;
      min-height: 30px;
      max-height: 30px;
      border-radius: 50%;
    }
  }
  .rank {
    margin: 0 8px;
  }
  .points {
    margin: 0 8px 0;
    svg {
      font-size: 26px;
    }
  }

  &.leader-board-shadow-1 {
    box-shadow: 0 0.25rem 0.25rem rgba(255, 215, 0, 1) !important;
  }

  &.leader-board-shadow-2 {
    box-shadow: 0 0.25rem 0.25rem rgba(192, 192, 192, 1) !important ;
  }

  &.leader-board-shadow-3 {
    box-shadow: 0 0.25rem 0.25rem rgba(205, 127, 50, 1) !important ;
  }
}

.points-reward .modal-content {
  background: linear-gradient(
    to top,
    rgba(205, 223, 255, 1) 0%,
    rgba(238, 226, 248, 1) 100%
  );
  .reward-points-modal {
    text-align: center;
    img.congrats {
      width: 200px;
      height: 200px;
      filter: drop-shadow(0 0 0.75rem rgb(220, 180, 20));
    }
  }
}

.toast-points-reward {
  width: 100%;
  max-width: 450px;
  background: linear-gradient(
    to top,
    rgba(205, 223, 255, 1) 0%,
    rgba(238, 226, 248, 1) 100%
  );
  .reward-points-modal {
    text-align: center;
    img.congrats {
      width: 100px;
      height: 100px;
      filter: drop-shadow(0 0 0.75rem rgb(220, 180, 20));
    }
  }
}

.file-style {
  width: 100%;
  margin: auto;
  padding: 20px;
  display: block;
  cursor: pointer;
  text-align: center;
  position: relative;
  border-radius: 15px;
  background-color: #f5f4f4;
  font-family: "Trebuchet MS";
  border: 2px dashed #cdc8c8;
  &:hover {
    background-color: #e3dcff;
  }
  .file-input {
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: absolute;
    &:focus {
      outline: none;
    }
  }
}

.new-content-nav-bar {
  width: 40px;
  margin: 0px;
  padding: 0px;
  height: 40px;
  display: flex;
  font-size: 30px;
  line-height: 0px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background: rgba(204, 204, 204, 0.5);
  span {
    padding-bottom: 5px;
  }
}
