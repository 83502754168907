%width {
  max-width: $general-width;
  width: 100%;
}

%transparent {
  color: white !important;
  color: transparent !important;
  background: inherit !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
}

%contrast-text {
  color: transparent;
  background: inherit;
  background-clip: text;
  -webkit-background-clip: text;
  filter: sepia(5) saturate(100) invert(1) grayscale(1) contrast(9);
}

%wrap-text {
  margin: 0;
  overflow: hidden;
  white-space: wrap;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  word-wrap: break-word !important;
  word-break: break-word !important;
}
