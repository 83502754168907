// Row&Col
.row .col {
  padding-top: 5px;
}

//col
.col .form-label {
  padding-top: 5px;
}
.hstack button#right-position {
  margin-left: auto;
}
//button
.hstack {
  .form-control {
    height: 47px;
  }
  .btn.btn-secondary {
    height: 47px;
  }
}

.btn.btn-secondary {
  color: #fff;
}

.w-0 {
  width: 0;
}

.btn svg {
  margin-bottom: 3px;
}

.btn.btn-success {
  color: #fff;
  font-size: 12px;
}
//accordion
.accordion-body svg {
  margin-bottom: 3px;
}

.accordion-content:hover {
  cursor: pointer;
  color: $primary;
}

//nav-link
.link-style {
  color: $dark;
  display: block;
  font-size: 15px;
  text-decoration: none;
  padding: var(--bs-nav-link-padding-y);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
//navbar
.navbar {
  box-shadow: 0 1px 3px rgba(3, 0, 71, 0.09);
}

.navbar-expand-lg .navbar-nav .nav-link {
  color: $dark;
  font-size: 15px;
}

.nav-item.dropdown:hover .dropdown-menu a:hover {
  color: $primary;
  background-color: $primary-light;
}

//dropdown
.nav-item.dropdown .dropdown-menu .nav-item .nav-link {
  font-size: 16px;
  padding: 4px 16px;
}

a.dropdown-item svg {
  margin-bottom: 3px;
}

.dropdown-toggle {
  &::after {
    display: none !important;
  }
  &::before {
    display: none !important;
  }
}

//table
.table {
  margin-top: 15px;
}

.position-pin-button {
  left: 20px;
  bottom: 30px;
  z-index: 1090;
  position: fixed;
}
