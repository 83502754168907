@media only screen and (min-width: 801px) {
  #border table,
  #border tbody,
  #border th,
  #border td {
    border: 1px solid #ccc;
  }

  .grey_14_table.pin-header {
    position: sticky;
    top: 0;
    tr {
      box-shadow: inset 0 -1px #ccc, inset 0 1px #ccc;
    }
  }
}

@media only screen and (max-width: 800px) {
  #no-more-tables table,
  #no-more-tables thead,
  #no-more-tables tbody,
  #no-more-tables th,
  #no-more-tables td,
  #no-more-tables tr {
    display: block;
  }

  #no-more-tables thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  #no-more-tables tr {
    border: 1px solid #ccc;
  }

  #no-more-tables td {
    min-height: 41px;
    border: none;
    width: 100%;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
    white-space: normal;
    text-align: left;
    &:before {
      position: absolute;
      top: 6px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      text-align: left;
      font-weight: bold;
      //title
      content: attr(data-title);
      overflow: auto;
      color: $secondary;
      font-size: 12px;
      text-transform: uppercase;
    }
  }
}

#reportTable {
  max-height: 70vh;
  overflow: auto;
}
@media print {
  #reportTable {
    all: unset;
  }
}
