//max width containers
$general-width: 1200px;
$font-size-base: 1rem;
// theme
$primary: #754ffe;
$secondary: #79758f;
$dark: #18113c;
$light: #fff;
$success: #19cb98;
$info: #29baf9;
$warning: #ffaa46;
$danger: #e53f3c;
$primary-light: #e3dcff;
$bg-color: #f5f4f8;

// input
$input-padding-y: 0.75rem;
$input-padding-x: 1.25rem;
$input-font-family: Inter, sans-serif;
$input-font-size: 14px;
//font
$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.65;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;
$h7-font-size: $font-size-base * 0.875;
$h8-font-size: $font-size-base * 0.75;

$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  7: $h7-font-size,
  8: $h8-font-size,
);
//
html {
  background-color: $bg-color;
}
body {
  scroll-behavior: smooth;
  line-height: 1.5;
  background-color: $bg-color;
  font-family: Inter, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
body::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
// import bootstrap styles at the bottom!
@import "~bootstrap/scss/bootstrap.scss";
@import "./styles/bootstrapCustomStyles.scss";
@import "./styles/tableStyles.scss";
@import "./styles/containerStyles.scss";
@import "./styles/style.scss";
@import "./styles/loaders.scss";
@import "./styles/animate.scss";
@import "./styles/extension.scss";
@import "./styles/media.scss";
@import "./styles/textEditor.scss";
