@import url("https://cdn.jsdelivr.net/npm/react-draft-wysiwyg@1.12.3/dist/react-draft-wysiwyg.css");
$hoverColor: #cccccc80;
$editorBgColor: #cccccc40;
$textZoneColor: #cccccc10;

.editor {
  border-radius: 6px;
  position: relative;
  border: 1px solid #ced4da;
  .demo-wrapper.rdw-editor-wrapper {
    display: flex;
    flex-direction: column;
    .rdw-editor-toolbar {
      all: unset;
      padding: 4px;
      display: flex;
      flex-wrap: wrap;
      user-select: none;
      background-color: $editorBgColor;
      border-bottom: 1px solid #ced4da;

      .rdw-dropdown-wrapper {
        color: #000;
        border-radius: 6px;
        border: 1px solid #ced4da;
        background-color: transparent;
        &:hover {
          box-shadow: none !important;
          background-color: $hoverColor;
        }
        .rdw-dropdown-selectedtext {
          text-wrap: nowrap;
        }
        a {
          color: #000;
          text-decoration: none;
        }
        .rdw-dropdown-optionwrapper {
          overflow: auto;
          border-radius: 6px;
          border: 1px solid #ced4da;
          box-shadow: none !important;
          &::-webkit-scrollbar {
            width: 3px;
          }
          .rdw-inline-dropdownoption {
            height: 30px;
          }
        }
      }
    }
    .rdw-option-wrapper:not(.rdw-colorpicker-option) {
      height: 30px;
      color: #000;
      margin: 0 3px;
      cursor: pointer;
      border-radius: 6px;
      border: 1px solid #ced4da;
      background-color: transparent;
      &:hover {
        background-color: $hoverColor;
        box-shadow: none !important;
      }
      .rdw-option-wrapper {
        border: none;
        background-color: none;
      }
    }
    .rdw-colorpicker-wrapper .rdw-colorpicker-modal {
      padding: 4px;
      box-shadow: none;
      border-radius: 6px;
      border: 1px solid #ced4da;
      .rdw-colorpicker-modal-style-label {
        padding: 0;
      }
      .rdw-option-wrapper {
        margin: 0px;
        padding: 1px;
        box-shadow: none;
        &:hover {
          scale: 1.1;
        }
      }
    }
    .rdw-emoji-wrapper .rdw-emoji-modal {
      padding: 4px;
      box-shadow: none;
      border-radius: 6px;
      border: 1px solid #ced4da;
      .rdw-emoji-icon:hover {
        scale: 1.1;
      }
    }
    .rdw-link-modal {
      padding: 4px;
      height: auto;
      box-shadow: none;
      border-radius: 6px;
      border: 1px solid #ced4da;
    }
    .custom-upload {
      input[type="file"] {
        display: none;
      }
      .custom-file-upload {
        cursor: pointer;
        padding: 6px 12px;
        display: inline-block;
        border: 1px solid #ccc;
      }
    }
    .editor-class-test {
      padding: 1rem;
      height: 400px;
      overflow: scroll;
    }
    .placeholder-ul {
      visibility: hidden;
    }
    .placeholder-li:hover {
      background: #f1f1f1;
    }
    .rdw-image-modal-upload-option-label
      img.rdw-image-modal-upload-option-image-preview {
      width: 200px;
      height: 60px;
    }
  }
  .editor-class {
    margin: 0;
    width: 100%;
    height: 220px;
    padding: 0 8px;
    resize: vertical;
    min-height: 150px;
    background: $textZoneColor;
    .public-DraftStyleDefault-block {
      margin: 0;
    }
    &::-webkit-scrollbar {
      width: 15px;
    }
    &::-webkit-resizer {
      border: 15px solid rgba(117, 79, 254, 0.2);
      outline: 1px solid rgba(117, 79, 254, 0.3);
      border-right-color: rgba(117, 79, 254, 0.9);
      border-bottom-color: rgba(117, 79, 254, 0.9);
      box-shadow: 0 0 5px 3px rgba(117, 79, 254, 0.1);
    }
  }
}
