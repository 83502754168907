@media screen and(max-width:350px) {
  .leader-board-page {
    .leader-board-container {
      .btn-group {
        .btn {
          font-size: 14px;
        }
      }
    }
  }
}

@media only screen and(max-width:406px) {
  .navbar-brand {
    margin: 0;
    max-width: calc(100% - 140px);
  }
}

@media screen and(max-width:425px) {
  .leader-board-card {
    font-size: 16px;
  }
}

@media only screen and (max-width: 450px) {
  .star {
    font-size: 50px;
  }
  .star_disable {
    font-size: 50px;
  }
}

@media only screen and (min-width: 451px) {
  .button-new-content {
    &:after {
      content: " Content";
    }
  }
  .button-new-answer {
    &:after {
      content: " Answer";
    }
  }
}

@media only screen and (max-width: 500px) {
  .chapter-title-info {
    font-size: 14px;
    flex-direction: column;
    align-items: flex-start !important;
    span {
      margin: 0 !important;
    }
  }
  .content-status {
    font-size: 14px;
    border-radius: 50%;
    span {
      display: none;
    }
  }
  .chapter-content-title-info {
    font-size: 14px;
  }
  .page-info {
    font-size: 20px !important;
  }
}

@media only screen and (max-width: 600px) {
  .quiz-text-wrap {
    margin: 0;
    overflow: hidden;
    white-space: wrap;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    word-wrap: break-word !important;
    word-break: break-word !important;
  }
}

@media screen and(max-width:701px) {
  .report-checkboxes {
    display: block;
  }
}

@media screen and(max-width:767px) {
  .row {
    padding-top: 0;
    flex-direction: column;
  }
  .customize-label {
    white-space: wrap;
  }
}

@media only screen and (max-width: 800px) {
  .container-settings {
    align-items: center;
    flex-direction: column;
    .settings {
      width: 100%;
      margin-right: 0;
    }
  }

  .container-courses {
    align-items: center;
    flex-direction: column-reverse;
    .table-content {
      width: 100%;
      margin-right: 0;
      max-width: 800px;
    }
    .course-content {
      margin-bottom: 10px;
      .video-container {
        padding-bottom: 50vh;
        iframe {
          height: 50vh;
        }
      }
    }
  }
  .course-view-content{
    position: static;
  }
}

@media only screen and(max-width:991px) {
  .dont-show-user-notify {
    display: none;
  }
  .show-user-notify {
    display: contents;
  }
}

@media screen and(min-width:992px) {
  .pre-scrollable {
    z-index: 1;
    height: auto;
    max-height: 80vh;
    overflow-x: hidden;
  }
  .submenu {
    position: fixed;
    background-color: #fff;
    border-radius: var(--bs-dropdown-border-radius);
    padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
    border: var(--bs-dropdown-border-width) solid
      var(--bs-dropdown-border-color);
  }

  ::-webkit-scrollbar {
    width: 7px;
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
  a.dropdown-toggle.nav-link:hover {
    color: $primary;
  }

  .nav-item.dropdown {
    &:hover .dropdown-menu {
      .nav-item .dropdown-menu {
        display: none;
      }
      display: block;
      margin: 0px;
      &:hover .nav-item:hover .dropdown-menu {
        display: block;
      }
    }
  }
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
