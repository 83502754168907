//loader
.loader {
  width: 100%;
  height: 4.8px;
  overflow: hidden;
  position: relative;
  display: inline-block;
  background: $primary-light;
  &:after {
    top: 0;
    left: 0;
    content: "";
    width: 192px;
    height: 4.8px;
    position: absolute;
    background: $primary;
    box-sizing: border-box;
    animation: animloader 2s linear infinite;
  }
}

@keyframes animloader {
  0% {
    left: 0;
    transform: translateX(-100%);
  }
  100% {
    left: 100%;
    transform: translateX(0%);
  }
}

.loader-nav-bar {
  width: 12px;
  height: 12px;
  display: block;
  color: #754ffe;
  margin: 15px auto;
  border-radius: 50%;
  position: relative;
  box-sizing: border-box;
  animation: animloaderNav 2s linear infinite;
}

@keyframes animloaderNav {
  0% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 -2px;
  }
  25% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 2px;
  }
  50% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 2px, -38px 0 0 -2px;
  }
  75% {
    box-shadow: 14px 0 0 2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 -2px;
  }
  100% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 2px, -14px 0 0 -2px, -38px 0 0 -2px;
  }
}
