//container
.main-container {
  padding-top: 1px;
  background-color: $bg-color;
  .routes {
    min-height: 80vh;
  }
}

.container_content {
  padding: 20px;
  @extend %width;
  margin: 20px auto;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(3, 0, 71, 0.09) !important;
}

.container-settings {
  display: flex;
  @extend %width;
  margin: 20px auto;
  align-items: flex-start;
  justify-content: space-between;
  .details {
    width: 100%;
    padding: 20px;
    border-radius: 8px;
    // max-width: 940px;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(3, 0, 71, 0.09) !important;
  }
  .settings {
    width: auto;
    padding: 20px;
    border-radius: 8px;
    margin: 0 20px 20px 0;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(3, 0, 71, 0.09) !important;
  }
}
.test-container {
  @extend %width;
  margin: 20px auto;
}
.star-rating {
  font-size: 80px;
  cursor: pointer;
  display: inline-block;
}

.star {
  color: #ccc;
}

.star.selected,
.star:hover {
  color: gold;
}

.star_disable {
  color: #ccc;
  &.selected {
    color: gold;
  }
}

.info-container {
  padding: 0;
  height: 200px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-image: url(../image/background/dashboard.jpg);
  .info-show-container {
    top: 120px;
    height: 80px;
    position: relative;
    background-color: #fff;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    .content-page {
      gap: 4px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .page-info {
        gap: 4px;
        display: flex;
        font-size: 26px;
        margin: 0 0 16px 0;
        flex-direction: row;
        align-items: center;
        padding: 16px 16px 16px 0;
        .page-content-name {
          @extend %wrap-text;
        }
      }
    }
  }
}

.container-courses {
  margin: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .table-content {
    width: 100%;
    max-width: 350px;
    border-radius: 8px;
    background-color: #fff;
    // .accordion-body {
    //   cursor: pointer;
    //   color: $primary;
    // }
  }
  .course-content {
    width: 100%;
    margin: 0 20px;
    &.style {
      padding: 10px;
      background: #fff;
      border-radius: 6px;
    }
    .video-container {
      height: 0;
      overflow: hidden;
      padding-top: 30px;
      position: relative;
      padding-bottom: 75vh;
      iframe {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
      }
    }
  }
}

.custom-file-container__image-preview {
  width: 100%;
  height: 250px;
  overflow: auto;
  margin: 20px 0;
  border-radius: 4px;
  background-color: #fff;
  background-size: 100% 250px;
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAiQAAAD6CAMAAACmhqw0AAAA+VBMVEUAAAD29u3u7unt7ent7enu7uju7uihoqCio6Gio6KjpKOkpaSmpqSmp6WoqKaqq6mqq6qrq6qsrautrauur62wsa6xsa+xsrCys7GztLK0tbK1trS2t7S3t7W4uba5ure6u7e7vLm8vbu9vrvAwL3Awb3DxMHFxcPGxsPHx8TIycXLzMjLzMnMzMnNzsrPz8vP0MzQ0M3S0s/U1NDV1dLX19TY2NTY2NXZ2dba2tXb29bc3Nfc3Njc3dnd3dre3tre39vg4Nvh4dzi4t3i4t7j497k5N/k5ODl5eDl5eHl5uLm5uHn5+Lo6OPp6eTq6uXr6+bs7Oft7eh54KxIAAAAB3RSTlMAHKbl5uztvql9swAABA1JREFUeNrt3VlT01AYgOG0oEEE910URNzFBVFcqCgKirLU/P8fI3QYbEOSdtrMyJzzvHfMlFx833NBQuY0SRrN8UwqabzZSJLGaYNQVacaSdMUVF0zGTMEVTeWmIH6BYkgESSCRJAIEkEiSCRIBIkgESSCRJAIEkEiQSJIBIkgESSCRJAIEgkSQSJIBIkgESSCRJBIkAgSQSJIBIkgESSCRIJEkAgSQSJIBIkgkSARJIJEkAgSQSJIBIkEiSARJIJEkAgSQSJIJEgEiSARJIJEkAgSQSJBIkgEiSARJIJEkAgSCRJBIkgEiSARJIJEgkSQ5PvxbdS+tyEJuZVb0+noTV579geSQGs/SOvqxiYkYfYwra+rbUhC7NNEjUjSJ5CE2P06jaTnIAmxKwe7vb468t3N14WOki1IAuzMwWrf1HCh3Q6S95AEWGe1b0/WlSCBBBJIIAkdSXvt1aNXa21IICld7dJU5+epJUggKV7tzuzRA4/ZHUggKVrtfNdjsXlIIClY7XLPw9NlSCA5vtqLPUguQgLJsdX+zv0fZhsSSPKrXckhWSn5jV8zG5DEiuR1DsnrEiOX0vMbkESKZDWHZLXMSFqsBJIIkOz1vn40sVdqpFgJJDHc3dzsQXKzwkihEkhiQLI+2f3y+3qVkSIlkMSAJFvsQrJYbaRACSRRIMlenj0UcPZlPyPHlUASB5Jsc+7cwevMc5v9jRxTAkkkSPbb+riVZYMYySuBJB4kJRUYySmBJHYkhUZ6lUASOZISIz1KIIkbSamRbiWQxIZkvT2YkS4lkESGpDV9tz2YkX9KIIkLSWs6TY+U9DFypASSqJC0OicfHSrpa2T/k5BEh6R1eDpWR8kARtIZSGJD0jo6QW1fySBGIIkOSavrlL27PwcxAklsSFo9JzFOppBAkl9ta5jTOiGJCslQRiCJCslwRiCJCcmQRiCJCMmwRiCJB8mXoU+YhyQaJM9TSCCBBBJIIIEEEkgggQQSSCCJAsnyzLA9hiQWJCfnSpBAAgkkkATXxFCnPxfU7iB5B0mAXT5Y7Z3t0Y087SDZgCTA7tX6bZ5TGSQBtlwrkgVIgmy+RiMXdiEJsp3b9Rn5nEESaC/O1/P3yMJuBkm4bX94O2rvNiKbWXRIBIkgESSCRJAIEkEiQSJIBIkgESSCRJAIEgkSQSJIBIkgESSCRIJEkAgSQSJIBIkgESQSJIJEkAgSQSJIBIkgkSARJIJEkAgSQSJIBIkEiSARJIJEkAgSQSJIJEgEiSARJIJEkAgSCRJBIkgEiSARJIJEkEiQCBJBIkgEiSARJIJEgkSQCBJBIkgEiSARJBIkgkSQ6P8gGTMDVTeWNA1B1TWTxmlTUFWnGknSaI4bhMoabzaSv+4BHFVoHZzfAAAAAElFTkSuQmCC);
}

.sign-in-form {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 100vh;
  position: absolute;
  background-color: #f4f5f8;
  .content {
    height: auto;
    padding: 40px;
    max-width: 470px;
    border-radius: 8px;
    margin: 10% auto 0;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(3, 0, 71, 0.09) !important;
  }
}

.set-first-password {
  max-width: 470px;
  margin: 15px auto;
}

.chart {
  margin: 10px 0;
  .chart-label {
    font-size: 19px;
    user-select: none;
    margin-bottom: 10px;
  }
  .chart-container {
    width: 100%;
    height: 400px;
    padding: 20px;
    border-radius: 6px;
    margin-bottom: 10px;
    border: 1px solid #000;
    .custom-tooltip {
      padding: 10px;
      border: 1px solid #ccc;
      background-color: white;
    }
  }
}

.container-user-cards {
  @extend %width;
  display: flex;
  flex-wrap: wrap;
  margin: 20px auto;

  .card-stats-details {
    width: 100%;
    display: flex;
    padding: 20px;
    max-width: 290px;
    background: #fff;
    border-radius: 8px;
    align-items: center;
    margin: 0 10px 10px 0;
    justify-content: space-around;
    box-shadow: 0 1px 3px rgba(3, 0, 71, 0.09) !important;
    .card-icon {
      padding: 8px;
      border-radius: 50%;
      svg {
        color: #fff;
        font-size: 75px;
      }
    }
    .card-stats {
      color: #fff;
      max-width: 150px;
      font-weight: 900;
      text-align: center;
      overflow-wrap: break-word;
    }
    &.rank-points {
      cursor: pointer;
      background: linear-gradient(to right bottom, #9370db, #ba55d3);
      &:hover {
        scale: 1.01;
      }
      .card-icon {
        background: #be37be;
      }
    }
    &.course-points {
      background: linear-gradient(to right bottom, #ffa500, #ffd700);
      .card-icon {
        background: orange;
      }
    }
    &.total-points {
      background: linear-gradient(to right bottom, #62aecd, #c0d5dc);
      .card-icon {
        background: #2ba8e2;
      }
    }
    .card-trending {
      color: #fff;
      padding: 5px 8px;
      margin-left: auto;
      font-size: 10.5px;
      border-radius: 6px;
      background-color: $info;
    }
    .card-progress {
      height: 24px;
      border-radius: 0;
      background-color: transparent;
    }
    .card-label {
      min-width: 40px;
      color: #6c757d;
      text-transform: uppercase;
      font-size: $h7-font-size;
    }
    .card-value {
      cursor: pointer;
      font-weight: bold;
      user-select: none;
      text-decoration: none;
      font-size: $h2-font-size;
    }
  }
}

.leader-board-container {
  width: 100%;
  height: 100vh;
  &.leader-board-background {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../image/background/leaderboard/gaming.jpg);
  }
}
//course chapter or course quiz
.course-chapter {
  padding: 10px;
  margin: 10px 0;
  border-radius: 7px;
  background-color: $bg-color;
  .chapter-info {
    gap: 2px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .dropdown {
      padding: 0 4px;
      margin-left: auto !important;
    }
    .chapter-title-info {
      display: flex;
      cursor: pointer;
      align-items: center;
      span {
        margin: 0 0 0 5px;
        white-space: nowrap;
        &.fw-bold {
          margin: 0 5px 0 0;
        }
      }
      .chapter-title-name {
        text-wrap: wrap;
        &:not(.quiz-text-wrap) {
          @extend %wrap-text;
        }
      }
      &:hover {
        .chapter-title-name {
          color: $primary;
          transform: scale(1.01);
          transition: transform 0.2s ease-in-out;
        }
      }
    }
    .button-new-content {
      white-space: nowrap;
      &:before {
        content: "Add";
      }
    }
    .button-new-answer {
      white-space: nowrap;
      margin-left: auto !important;
      &:before {
        content: "Add";
      }
    }
  }
  .content-status {
    padding: 8px;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    border-radius: 15px;
    svg {
      margin: 0 0 4px;
    }
  }

  .chapter-content {
    margin: 10px 0;
    border-radius: 7px;
    background: white;
    .chapter-content-info {
      gap: 2px;
      width: 100%;
      padding: 10px;
      display: flex;
      border-radius: 7px;
      align-items: center;
      justify-content: space-between;
      .chapter-content-title-info {
        gap: 4px;
        display: flex;
        cursor: pointer;
        align-items: center;
        .content-title-name {
          text-wrap: wrap;
          &:not(.quiz-text-wrap) {
            @extend %wrap-text;
          }
        }
        span {
          white-space: nowrap;
        }
      }
      .content-status {
        margin-left: auto !important;
        font-size: 14px;
      }
    }
  }
}

.course-view-content {
  top: 96px;
  width: 100%;
  padding: 10px;
  position: sticky;
  background: #fff;
  border-radius: 6px;
  margin: 0 20px 20px;
  .title {
    gap: 5px;
    display: flex;
    font-size: 20px;
    margin: 0 0 10px 0;
    align-items: center;
    justify-content: space-between;
  }
  .error {
    color: $danger;
    font-size: 20px;
    text-align: center;
  }
  .description {
    gap: 5px;
    display: flex;
    margin: 10px 0;
    // align-items: center;

    justify-content: space-between;
    button {
      align-self: self-start;
      margin-left: auto !important;
    }
  }
  .content-question {
    padding: 6px;
    margin: 10px 0;
    border-radius: var(--bs-border-radius) !important;
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    .question {
      font-size: 20px;
      font-weight: bold;
    }
    hr {
      margin: 8px 0;
    }
    .answer {
      gap: 4px;
      padding: 5px;
      display: flex;
      cursor: pointer;
      user-select: none;
      border-radius: var(--bs-border-radius) !important;
      &:hover {
        background-color: $primary-light;
      }
    }
  }
}
